/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import ReactMarkdown from "react-markdown"
import unwrapImages from "remark-unwrap-images"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Box, Text, Grid } from "theme-ui"
import { useSpring, animated, config } from "react-spring"
import { PopInLeft } from "react-spring-pop"
import { IconContext } from "react-icons"
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineClockCircle,
} from "react-icons/ai"
import moment from "moment"
import momentDurationFormatSetup from "moment-duration-format"
momentDurationFormatSetup(moment)

const ProjectTemplate = ({ data, pageContext }) => {
  var rastgele = Math.random() * 10 - Math.random() * 10
  var rastgele2 = Math.random() * 10 - Math.random() * 10
  const datestart = moment(data.strapiProject.date)
  // const dateyear = moment(data.strapiProject.date).format('YYYY');
  const dateend = moment(data.strapiProject.enddate).add(1, "days")
  const dateduration = dateend.diff(datestart, "days")
  const datedur = moment
    .duration(dateduration, "days")
    .format("y [years], M [months], w [weeks], d [days]", {
      largest: 1,
    })
  const delay = 125
  const fade = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: "translate3d(0,-25px,0)" },
    to: async next => {
      await next({ opacity: 1, transform: "translate3d(0,0,0)" })
    },
  })
  const fade2 = useSpring({
    config: config.stiff,
    from: {
      opacity: 0,
      transform: `perspective(600px) skew(-20deg, -20deg) translate3d(-50px,-50px,0) rotateZ(${rastgele}deg)`,
    },
    to: async next => {
      await next({
        opacity: 1,
        transform: `perspective(600px) skew(0deg, 0deg)   translate3d(0,0,0) rotateZ(${rastgele2}deg)`,
        delay: delay * 2,
      })
    },
  })
  const fade3 = useSpring({
    config: config.gentle,
    from: { opacity: 0.01, transform: "translate3d(0,100px,0)" },
    to: async next => {
      await next({
        opacity: 1,
        transform: "translate3d(0,0,0)",
        delay: delay * 2,
      })
    },
  })

  const AnimatedImg = animated(Img)
  const AnimatedBox = animated(Box)
  const AnimatedText = animated(Text)

  return (
    <Layout>
      <SEO title={data.strapiProject.title} />

      <Grid gap={[1, 2, 3]} columns={["1fr", "repeat(12,1fr)"]}>
        <AnimatedImg
          style={fade}
          loading={"eager"}
          sx={{
            // zIndex: "-1",
            gridColumnStart: ["1","4"],
            gridColumnEnd: ["1","13"],
            gridRowStart: ["1","1"],
            gridRowEnd: ["1","1"],
            borderRadius: 5,
            // height: ['25vh', '75%'],
          }}
          fluid={data.strapiProject.cover.childImageSharp.fluid}
        />
        <Box
          sx={{
            gridRowStart: ["2","1"],
            gridRowEnd: ["2","1"],
            mt:["-100px","initial"],
            width: ['50%','initial'],
            // mx: ['auto', 'initial'],
            gridColumnStart: "1",
            gridColumnEnd: "5",
            alignSelf: "center",
            filter: "drop-shadow(-1px 2px 2px rgba(50, 50, 0, 0.5))",
          }}
        >
          <AnimatedBox
            style={fade2}
            className="postit" //toggle
            sx={{
              boxShadow: "project.postit",
              p: ["2","4"],
              backgroundColor: "postit",
              clipPath: "polygon(0 0, 100% 0, 100% 90%, 95% 100%, 0 100%)",
              color: "text",
              backgroundImage:
                "-webkit-linear-gradient(hsla(0,0%,0%,.025), hsla(0,0%,100%,.05) 33%, hsla(0,0%,0%,.05) 33%, hsla(0,0%,100%,.05) 67%, hsla(0,0%,0%,.05) 67%, hsla(0,0%,100%,.025))",
              // clipPath: "ellipse(150% 150% at -10% -10%)",
            }}
          >
            <animated.h1 sx={{fontSize:['5','6']}}>{data.strapiProject.title}</animated.h1>
            <Text
              sx={{
                fontSize: ["1","2"],
                fontWeight: "body",
                pb: "3",
                borderBottom: "dotted 2px black",
              }}
            >
              {data.strapiProject.summary}
            </Text>
            <Text
              sx={{
                textTransform: "uppercase",
                py: "2",
                overflow: "hidden",
                fontWeight: "medium",
                fontSize: ["0","2"],
              }}
            >
              {data.strapiProject.category}
              {data.strapiProject.enddate && (
                <>
                  <AiOutlineClockCircle
                    sx={{
                      size: "1.5em",
                      display: "inline-block",
                      verticalAlign: "top",
                      py: "1",
                      ml: ["2","3"],
                    }}
                  />
                  <AnimatedText sx={{ ml: "0", display: "inline" }}>
                    {datedur}
                  </AnimatedText>
                </>
              )}
            </Text>
          </AnimatedBox>
        </Box>
      </Grid>
      <AnimatedBox style={fade3} className="projectMDX">
        <Grid
          className="grid-container"
          gap={[1, 2, 3]}
          columns={["1fr", "repeat(12,1fr)"]}
          sx={{ display: ["block", "grid"] }}
        >
          <ReactMarkdown
            source={data.strapiProject.description}
            plugins={[unwrapImages]}
            linkTarget={"_blank"}
            renderers={{
              image: ({ src, alt }) => {
                const image = data.strapiProject.description_images.find(
                  element => element.base === src
                )
                return (
                  <div className={"projectImage"}>
                    <Img fluid={image.childImageSharp.fluid} alt={alt} />
                  </div>
                )
              },
              heading: ({ children, level }) => {
                return (
                  <PopInLeft
                    once={true}
                    tension={120}
                    friction={14}
                    threshold={0}
                    className={"projectHeader"}
                    sx={{ mt: "3", pb: "2", zIndex: "1" }}
                  >
                    <Text
                      as={`h${level}`}
                      sx={{
                        fontFamily: "heading",
                        transform: `rotateZ(${Math.floor(
                          Math.random() * 5 - Math.random() * 5
                        )}deg)`,
                      }}
                    >
                      <span
                        css={"-webkit-backface-visibility: hidden;"}
                        sx={{}}
                      >
                        {children}
                      </span>
                    </Text>
                  </PopInLeft>
                )
              },
            }}
          />
        </Grid>
      </AnimatedBox>

      {/* PREVIOUS/NEXT NAVIGATION */}
      <Grid
        px={["0", "2"]}
        pb={"4"}
        pt={"0"}
        sx={{
          lineHeight: "1.25",
          width: "100%",
          position: "fixed",
          left: "0",
          bottom: "0",
        }}
        columns={["max-content auto max-content"]}
      >
        <IconContext.Provider value={{ size: "4em" }}>
          {pageContext.prevtitle && (
            <Box
              sx={{
                float: "left",
                position: "relative",
                gridColumnStart: "1",
                fontSize: ["0", "1"],
              }}
            >
              <Link
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  gridTemplateRows: "auto",
                  color: "gray",
                  py: "3",
                  pr: "3",
                  borderRadius: "10px",
                  backgroundColor: "rgba(255,255,255,0)",
                  opacity: "0.5",
                  ":hover": {
                    color: "primary",
                    transition: "opacity color background-color 0.3s ease-in",
                    backgroundColor: "rgba(255,255,255,0.75)",
                    opacity: "1",
                  },
                  ":hover>div": {
                    transition: "opacity 0.3s ease-in",
                    opacity: "1",
                  },
                }}
                to={`/projects/${pageContext.prev}`}
              >
                <AiOutlineArrowLeft
                  sx={{
                    display: "block",
                    gridColumnStart: "1",
                    gridRowStart: "1",
                  }}
                />
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    gridColumnStart: "2",
                    gridRowStart: "1",
                    opacity: "0",
                    fontSize: "1",
                    fontWeight: "body",
                  }}
                >
                  Previous project:
                </Box>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    pt: "16px",
                    gridColumnStart: "2",
                    gridRowStart: "1",
                    opacity: "0",
                    fontSize: "2",
                    fontWeight: "heading",
                  }}
                >
                  {pageContext.prevtitle}
                </Box>
              </Link>
            </Box>
          )}
          {pageContext.nexttitle && (
            <Box
              sx={{
                float: "right",
                position: "relative",
                gridColumnStart: "3",
                textAlign: "right",
                fontSize: ["0", "1"],
              }}
            >
              <Link
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  gridTemplateRows: "auto",
                  color: "gray",
                  py: "3",
                  pl: "3",
                  borderRadius: "10px",
                  opacity: "0.5",
                  ":hover": {
                    color: "primary",
                    transition: "opacity color background-color 0.3s ease-in",
                    backgroundColor: "rgba(255,255,255,0.75)",
                    opacity: "1",
                  },
                  ":hover>div": {
                    transition: "opacity 0.3s ease-in",
                    opacity: "1",
                  },
                }}
                to={`/projects/${pageContext.next}`}
              >
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    gridColumnStart: "1",
                    gridRowStart: "1",
                    opacity: "0",
                    fontSize: "1",
                    fontWeight: "body",
                  }}
                >
                  Next project:
                </Box>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    pt: "16px",
                    gridColumnStart: "1",
                    gridRowStart: "1",
                    opacity: "0",
                    fontSize: "2",
                    fontWeight: "heading",
                  }}
                >
                  {pageContext.nexttitle}
                </Box>
                <AiOutlineArrowRight
                  sx={{
                    display: "block",
                    gridColumnStart: "2",
                    gridRowStart: "1",
                  }}
                />
              </Link>
            </Box>
          )}
        </IconContext.Provider>
      </Grid>
      {/* PREVIOUS/NEXT NAVIGATION */}
    </Layout>
  )
}

export default ProjectTemplate

export const query = graphql`
  query ProjectTemplate($id: String!) {
    strapiProject(id: { eq: $id }) {
      id
      slug
      title
      category
      description
      description_images {
        childImageSharp {
          original {
            src
          }
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        base
      }
      date
      enddate
      summary

      cover {
        childImageSharp {
          fluid(
            maxWidth: 1200
            maxHeight: 600
            cropFocus: CENTER
            quality: 100
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

// childStrapiProjectContent {
//   childMdx {
//     body
//     id
//   }
// }
